import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function TestimonialsSection() {
  const [curSectionIndex, _setCurSectionIndex] = React.useState(0);
  const [lastUpdated, setLastUpdated] = React.useState(null);

  const data = useStaticQuery(graphql`
    query TestimonialsSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "get-involved"}}) {
        frontmatter {
          testimonials {
            title
            description
            name
            location
          }
        }
      }
    }
  `);
  const {testimonials} = data.markdownRemark.frontmatter;

  const setCurSectionIndex = React.useCallback((newIndex) => {
    _setCurSectionIndex(newIndex);
    setLastUpdated(Date.now());
  }, []);

  React.useEffect(() => {
    const transitionInterval = 3500; // secs
    const timeoutId = setTimeout(() => {
      setCurSectionIndex(c => (c + 1) % testimonials.length);
    }, transitionInterval);
    return () => clearTimeout(timeoutId);
  }, [lastUpdated, setCurSectionIndex, testimonials]);

  return (
    <div>
      <div className="relative w-3/4 mx-auto bg-red-50 min-h-[36rem] sm:min-h-[24rem] overflow-hidden">
        {testimonials.map(({title, description, name, location}, index) => (
          <div key={index} className={`absolute transition-all duration-500 m-5 md:m-20 left-0 right-0 ${
            curSectionIndex === index ? "opacity-100" : "opacity-0"}`}>
            <p className="text-2xl mb-5">{title}</p>
            <p className="mb-10 text-justify">{description}</p>
            <p className="text-sm font-bold mb-5">{name}</p>
            <p>{location}</p>
          </div>
        ))}
        <div className="absolute bottom-10 flex flex-row justify-center left-0 right-0">
          {testimonials.map((t, index) => (
            <div key={index} className="p-2">
              <button onClick={() => setCurSectionIndex(index)}
                      className={`h-4 w-4 rounded-full transition-all duration-500 ${
                        curSectionIndex === index ? "bg-gray-800" : "bg-gray-400"}`}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TestimonialsSection;
