import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";

function IntroSection() {
  const data = useStaticQuery(graphql`
    query GetInvolvedIntroSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "get-involved"}}) {
        frontmatter {
          getInvolvedIntroSection {
            title
            description
            image
          }
        }
      }
    }
  `);
  const sections = data.markdownRemark.frontmatter.getInvolvedIntroSection;

  return (
    <div>
      <FloatingSection>
        <div className="mt-10 md:mt-36 md:flex flex-row">
          <div className="flex-1 pl-20">
            <div className="relative w-3/4 mx-auto">
              <div className="absolute w-full h-full top-20 -left-20 bg-yellow-500"/>
              <img src={sections[0].image} alt="Volunteer" className="relative w-full"/>
            </div>
          </div>
          <div className="flex-1 p-20 pt-40">
            <p className="text-red-400 text-2xl font-bold mb-10">{sections[0].title}</p>
            <p className="text-justify">{sections[0].description}</p>
          </div>
        </div>
      </FloatingSection>

      <FloatingSection>
        <div className="mb-20 md:flex flex-row">
          <div className="flex-1 p-20 md:pt-40">
            <p className="text-red-400 text-2xl font-bold mb-10">{sections[1].title}</p>
            <p className="text-justify">{sections[1].description}</p>
          </div>
          <div className="flex-1 pl-20">
            <img src={sections[1].image} alt="Volunteer" className="relative w-3/4"/>
          </div>
        </div>
      </FloatingSection>
    </div>
  );
}

export default IntroSection;
