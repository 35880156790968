import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";

function VolunteerForm() {
  const data = useStaticQuery(graphql`
    query VolunteerFormQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "get-involved"}}) {
        frontmatter {
          volunteerInstructions
          volunteerFormDetails {
            isInternshipEnabled
            onCampusAccommodationFees
          }
          volunteerTips
          volunteerFormImage
        }
      }
    }
  `);
  const {
    volunteerInstructions, volunteerFormDetails: {isInternshipEnabled, onCampusAccommodationFees},
    volunteerTips, volunteerFormImage
  } = data.markdownRemark.frontmatter;

  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [errors, setErrors] = React.useState(null);

  const validateDates = () => {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    if (!isNaN(start) && !isNaN(end) && start > end) {
      setErrors({date: 'End date must be after start date.'});
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    console.log(event);
    if (!validateDates()) {
      console.log("Prevent default submit")
      event.preventDefault();
      return false;
    }
    return true;
  }

  return (
    <>
      <div id="volunteer" className="bg-gray-50 md:flex flex-row">
        <div className="hidden md:block flex-1 relative">
          <img src={volunteerFormImage} alt="Volunteer" className="absolute w-full h-full object-cover"/>
        </div>
        <form
          name="volunteer" method="post" data-netlify="true"
          className="flex flex-col flex-1 p-5 md:px-32" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="volunteer"/>

          <a href="/get-involved/international-volunteer-application"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-center py-2 px-4 rounded mb-5">
            International Volunteers/Interns Apply Here
          </a>

          <p className="text-2xl md:text-2xl font-bold text-blue-400">FOR DOMESTIC VOLUNTEERS / INTERNSHIPS, PLEASE APPLY BELOW</p>

          <p className="mt-5 text-justify text-gray-600">{volunteerInstructions}</p>

          <p className="mt-5 mb-2">Are you applying as a Volunteer or for Internship?</p>
          <div className="flex flex-row mb-5">
            <label className="mr-5">
              <input type="radio" name="applicationType" value="volunteer" required className="m-2"/>
              Volunteer
            </label>
            <label className={`${isInternshipEnabled ? "" : "text-gray-500"}`}>
              <input type="radio" name="applicationType" value="internship" className="m-2"
                     disabled={!isInternshipEnabled}/>
              Internship
            </label>
          </div>

          <input name="name" className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Name" required/>

          <textarea name="address" rows={3} className="bg-gray-200 p-4 rounded-md mb-2" placeholder="Address"
                    required/>

          <div className="flex flex-col md:flex-row mb-5">
            <input name="mobile" className="flex-1 bg-gray-200 p-4 rounded-md md:mr-5"
                   placeholder="Mobile Number"
                   required/>
            <input name="email" className="flex-1 bg-gray-200 p-4 rounded-md md:ml-5"
                   placeholder="E-mail Address"
                   required/>
          </div>

          <p className="mb-2">Date of Birth</p>
          <input name="dateOfBirth" className="flex-1 bg-gray-200 p-4 rounded-md mb-5"
                 type="date" required/>

          <p className="mb-2">Please mention your current academic details and Organization below.</p>
          <input name="currentAcademicDetails" className="flex-1 bg-gray-200 p-4 rounded-md mb-2"
                 placeholder="Academic Details and Organization" required/>

          <input name="howDidYouHearOfRaphael" className="flex-1 bg-gray-200 p-4 rounded-md mb-2"
                 placeholder="How did you first hear of Raphael?" required/>

          <input name="reasonsForApplying" className="flex-1 bg-gray-200 p-4 rounded-md mb-2"
                 placeholder="What are your reasons for applying to Raphael?" required/>

          <p className="mb-2">Do you want to inform us about any other requirements that you might have during the program?</p>
          <input name="otherRequirements" className="flex-1 bg-gray-200 p-4 rounded-md mb-2"
                 placeholder="Other Requirements"/>

          <p className="mb-2">What are your preferred dates to volunteer? Enter Start and End dates below.</p>
          <div className="mb-2">
            <div className="flex flex-col md:flex-row">
              <input name="applyFromDate" className="flex-1 bg-gray-200 p-4 rounded-md md:mr-5"
                     type="date" required onChange={ev => setFromDate(ev.target.value)}/>
              <input name="applyToDate" className="flex-1 bg-gray-200 p-4 rounded-md md:ml-5"
                     type="date" required onChange={ev => setToDate(ev.target.value)}/>
            </div>
            {errors?.date && <p className="mt-5 text-red-500">{errors.date}</p>}
          </div>

          <p className="mb-5">
            Would you require accommodation on campus (Provided strictly to those who would be volunteering in Raphael
            at a charge of Rs {onCampusAccommodationFees}/- per day which includes meals)?
          </p>
          <div className="flex flex-row mb-2">
            <label className="mr-5">
              <input type="radio" name="isOnCampusAccommodationRequired" value="yes" required className="m-2"/>
              YES
            </label>
            <label>
              <input type="radio" name="isOnCampusAccommodationRequired" value="no" className="m-2"/>
              NO
            </label>
          </div>

          <p className="mb-5">Name & contact details of two referees and his/her connection with you:
            (Optional)</p>
          <div className="flex flex-col md:flex-row mb-2">
            <input name="referee1Name" className="flex-1 bg-gray-200 p-4 rounded-md mb-2 md:mr-5"
                   placeholder="First Referee's Name"
            />
            <input name="referee1MobileOrEmail" className="flex-1 bg-gray-200 p-4 rounded-md mb-2 md:ml-5"
                   placeholder="E-mail / Mobile"
            />
          </div>
          <div className="flex flex-col md:flex-row mb-5">
            <input name="referee2Name" className="flex-1 bg-gray-200 p-4 rounded-md mb-2 md:mr-5"
                   placeholder="Second Referee's Name"
            />
            <input name="referee2MobileOrEmail" className="flex-1 bg-gray-200 p-4 rounded-md mb-2 md:ml-5"
                   placeholder="E-mail / Mobile"
            />
          </div>

          <input type="submit" value="APPLY" className="font-bold text-white bg-blue-400 hover:bg-blue-600 w-fit
        px-20 py-2 rounded-full mx-auto"/>
        </form>
      </div>

      <div className="md:w-1/2 p-20 mx-auto">
        <FloatingSection>
          {volunteerTips.map((tip, index) => (
            <p key={index} className="text-justify mb-5">{tip}</p>
          ))}
        </FloatingSection>
      </div>
    </>
  );
}

export default VolunteerForm;
