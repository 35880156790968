import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";

function CommunicationSection() {
  const data = useStaticQuery(graphql`
    query CommunicationSectionQuery {
      getInvolved: markdownRemark(frontmatter: {templateKey: {eq: "get-involved"}}) {
        frontmatter {
          communications {
            office
            postalAddress
            location
            image
          }
        }
      }
      common: markdownRemark(frontmatter: {dataId: {eq: "common"}}) {
        frontmatter {
          contactDetails {
            email
            phone
            phoneDisplay
          }
        }
      }
    }
  `);
  const {office, postalAddress, location, image: communicationImage} = data.getInvolved.frontmatter.communications;
  const {email, phone, phoneDisplay} = data.common.frontmatter.contactDetails;

  return (
    <div className="bg-blue-400 mb-20">
      <FloatingSection>
        <div className="md:flex flex-row">
          <div className="flex-1 p-5 md:p-32 text-white">
            <p className="text-2xl font-bold mb-10">Communications</p>

            <a className="block mb-5" href={`mailto:${email}`}><b>Email</b>: {email}</a>
            <a className="block mb-10" href={`tel:${phone}`}><b>Telephone (landline)</b>: {phoneDisplay}</a>

            <div className="mb-10">
              {office.map((line, index) => (
                <p className="text-justify" key={index}>{line}</p>
              ))}
            </div>

            <div className="mb-10">
              <p className="font-bold">Postal Address:</p>
              {postalAddress.map((line, index) => (
                <p className="text-justify" key={index}>{line}</p>
              ))}
            </div>

            <div>
              <p className="font-bold">Location:</p>
              {location.map((line, index) => (
                <p className="text-justify" key={index}>{line}</p>
              ))}
            </div>
          </div>

          <div className="flex-1">
            <img src={communicationImage} alt="Communications" className="w-2/3 mx-auto md:-translate-y-1/4"/>
          </div>
        </div>
      </FloatingSection>
    </div>
  );
}

export default CommunicationSection;
