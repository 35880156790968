import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import IntroSection from "./introSection";
import TestimonialsSection from "./testiomonials";
import VolunteerForm from "./volunteer";
import CommunicationSection from "./communication";

const GetInvolvedPage = (props) => {

  const data = useStaticQuery(graphql`
    query GetInvolvedPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "get-involved"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/get-involved" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <IntroSection/>
      <TestimonialsSection/>
      <VolunteerForm/>
      <CommunicationSection/>

      <HelpMethodsSection/>
    </Layout>
  );
};

GetInvolvedPage.propTypes = {};

export default GetInvolvedPage;
